import {AfterViewInit, ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {CL_CHANNEL, ICLCallbacksInterface, ICLEvent, LinkerService, Register, Run, StepDown} from "@clavisco/linker";
import {StorageService} from "../../core/services/storage.service";
import {IMenuNode} from "@clavisco/menu";
import {catchError, finalize, map, tap} from "rxjs";
import {AlertsService, CLModalType, CLToastType, ModalService} from "@clavisco/alerts";
import {PermsService} from "../../core/services/perms.service";
import {AuthenticationService} from "../../core/services/authentication.service";
import {
  SelectCompanyDialogComponent
} from "../../pages/matDialog/select-company-dialog/select-company-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {GeneralConfigsService} from "../../core/services/general-configs.service";
import {PermissionsSelectedResponse} from "../../models/responses";
import {GetError, Structures} from "@clavisco/core";
import {Token} from "../../models/FeCredentialsModel";
import {PermissionsSelectedModel} from "../../core/interfaces/i-perms";
import ICLResponse = Structures.Interfaces.ICLResponse;

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class ContainerMenuComponent implements OnInit, AfterViewInit {

  MenuId: string = 'menuId';

  Logo: string = '/assets/Logo-Clavisco.png';

  Title: string = 'Bienvenido a FE Console';

  User: string = this.storage.GetItem<Token>('currentUser')?.UserName ;

  Toggle: Boolean = true;

  MenuNodes: IMenuNode[] = [];


  callbacks: ICLCallbacksInterface<CL_CHANNEL> = {
    Callbacks: {},
    Tracks: []
  };
  // objeto con la compannia seleccionada
  SelectedCompany: any;
  // lista con los permisos de la aplicacion
  permsList: any[] = [];
  txtcompany:string = 'company';
  txtaccion:string='';
  constructor(
    //private authService: AuthService,
    private router: Router,
    @Inject('LinkerService') private linkerService: LinkerService,
    private storage: StorageService,
    private permsService: PermsService,
    private authenticationService: AuthenticationService,
    private alertService: AlertsService,
    private dialog: MatDialog,
    private generalConfig: GeneralConfigsService,
    private cdr: ChangeDetectorRef,
    private modalService: ModalService,

  ) {
    this.MenuNodes = [
      {
        Key: "home",
        Description: "Inicio",
        Icon: "house",
        Route: "/home",
        Visible: true,
        Nodes: []
      },
      {
        Key: "documents",
        Description: "Documentos",
        Icon: "folder_open",
        Route: "",
        Visible: true,
        Nodes: [{
          Key: "issued_documents",
          Description: "Documentos Emitidos",
          Icon: "",
          Route: "/issued_documents",
          Visible: true,
          Nodes: []
        },
          {
            Key: "accept_documents",
            Description: "Aceptación Documentos",
            Icon: "",
            Route: "/accept_documents",
            Visible: true,
            Nodes: []
          },
          {
            Key: "accept_documents_gt",
            Description: "Aceptación Documentos GT",
            Icon: "",
            Route: "/accept_documents_gt",
            Visible: true,
            Nodes: []
          },
          {
            Key: "reception_documents",
            Description: "Recepción Documentos",
            Icon: "",
            Route: "/reception_documents",
            Visible: true,
            Nodes: []
          },
          {
            Key: "mailParser",
            Description: "Logs de Correo de Recepción",
            Icon: "",
            Route: "/mailParser",
            Visible: true,
            Nodes: []
          },
          {
            Key: "createFE",
            Description: "Creación FE",
            Icon: "",
            Route: "/createDocument/01",
            Visible: true,
            Nodes: []
          },
          {
            Key: "createND",
            Description: "Creación ND",
            Icon: "",
            Route: "/createDocument/02",
            Visible: true,
            Nodes: []
          },
          {
            Key: "createNC",
            Description: "Creación NC",
            Icon: "",
            Route: "/createDocument/03",
            Visible: true,
            Nodes: []
          },
          {
            Key: "createFEC",
            Description: "Creación FEC",
            Icon: "",
            Route: "/createDocument/08",
            Visible: true,
            Nodes: []
          }]
      },
      {
        Key: "reports",
        Description: "Reportes",
        Icon: "print",
        Route: "/docReport",
        Visible: true,
        Nodes: []
      },
      {
        Key: "settings",
        Description: "Configuración",
        Icon: "settings_suggest",
        Route: "",
        Visible: true,
        Nodes: [{
          Key: "company",
          Description: "Compañía",
          Icon: "",
          Route: "/company",
          Visible: true,
          Nodes: []
        },
          {
            Key: "udfs",
            Description: "Campos Definidos por Usuario (UDFs)",
            Icon: "",
            Route: "/udfs",
            Visible: true,
            Nodes: []
          },
          {
            Key: "registerUsers",
            Description: "Registro Usuarios",
            Icon: "",
            Route: "/registerUsers",
            Visible: true,
            Nodes: []
          },
          {
            Key: "completeUsersRegistration",
            Description: "Completar Registro de Usuarios",
            Icon: "",
            Route: "/completeUsersRegistration",
            Visible: true,
            Nodes: []
          },
          {
            Key: "userAssigment",
            Description: "Asignación de Usuarios",
            Icon: "",
            Route: "/userAssignment",
            Visible: true,
            Nodes: []
          },
          {
            Key: "groups",
            Description: "Grupos",
            Icon: "",
            Route: "/groups",
            Visible: true,
            Nodes: []
          },
          {
            Key: "numbering",
            Description: "Numeración",
            Icon: "",
            Route: "/numbering",
            Visible: true,
            Nodes: []
          },
          {
            Key: "permsByRol",
            Description: "Permisos por Rol",
            Icon: "",
            Route: "/permsByRol",
            Visible: true,
            Nodes: []
          },
          {
            Key: "Rol",
            Description: "Roles",
            Icon: "",
            Route: "/Rol",
            Visible: true,
            Nodes: []
          },
          {
            Key: "rolUserCompany",
            Description: "Roles por usuario",
            Icon: "",
            Route: "/rolUserCompany",
            Visible: true,
            Nodes: []
          },
          {
            Key: "updateUserInfo",
            Description: "Actualizar Información del Usuario",
            Icon: "",
            Route: "/updateUserInfo",
            Visible: true,
            Nodes: []
          },
          {
            Key: "sucursal",
            Description: "Sucursal",
            Icon: "",
            Route: "/sucursal",
            Visible: true,
            Nodes: []
          },
          {
            Key: "mailParserConfig",
            Description: "Procesador de Correos",
            Icon: "",
            Route: "/mailParserConfig",
            Visible: true,
            Nodes: []
          },
          {
            Key: "emailInbox",
            Description: "Asignación de bandejas",
            Icon: "",
            Route: "/emailInbox",
            Visible: true,
            Nodes: []
          },
        ]
      },
      {
        Key: "generalConfigs",
        Description: "Configuración General",
        Icon: "settings",
        Route: "/generalConfigs",
        Visible: true,
        Nodes: []
      },
      {
        Key: "logout",
        Description: "Cerrar sesión",
        Icon: "logout",
        Route: "/login",
        Visible: true,
        Nodes: []
      },
    ]
  }

  ngAfterViewInit():void{
    this.generalConfig.Name.subscribe((data) => {
      this.txtaccion = data;
      this.cdr.detectChanges();
    });
  }
  ngOnInit(): void {

    if(this.storage.GetSelectedCompany()) this.SelectedCompany = JSON.parse(this.storage.GetSelectedCompany());
    this.txtcompany= this.SelectedCompany?.companyName;
    this.GetPermsByUser();
    Register(this.MenuId, CL_CHANNEL.OUTPUT, this.ClickMenuOption, this.callbacks);
    this.linkerService.Flow()?.pipe(
      StepDown<CL_CHANNEL>(this.callbacks),
    ).subscribe({
      next: callback => Run(callback.Target, callback, this.callbacks.Callbacks),
      error: error => console.log(`Error:`, error)
    });
  }

  // cambia los permisos en las diferentes tablas, ya sea si estan habilitados o deshabilitados
  GetPermsByUser():void {
    this.permsService.GetPermsByUser(this.SelectedCompany?.companyId).subscribe({
      next:(data:ICLResponse<PermissionsSelectedModel[]>):void=>{
        if (data.Data.length) {
          this.storage.RemovPerms();
          this.storage.SetPerms(data.Data);
          this.authenticationService.setCurrentPermsValue(data.Data);
          console.log(data.Data)
          this.MenuNodes.forEach(x=>{
            if (x.Key!="home" && x.Key!="logout") {
              if (x.Description === 'General Config' && this.User !== 'sys@clavisco.com'){
                x.Visible = false;
              }else {

                const requiredPerm = this.GetRequiredPerm(x.Key);

                if (
                  (requiredPerm === 'M_Reports' && !data.Data.some((perm: any) => ['S_DocumentReport', 'S_DocumentReceptionReport'].includes(perm.Name))) ||
                  (x.Key !== 'generalConfigs' &&  !data.Data.some((perm: any) => perm.Name === requiredPerm))
                ) {
                  x.Visible = false;
                }
                x.Nodes.forEach(y => {
                  if(!data.Data.find((perm:any)=> perm.Name===this.GetRequiredPerm(y.Key))){
                    y.Visible=false;
                  }

                  if(y.Key === 'emailInbox' && this.User === 'sys@clavisco.com') {
                    y.Visible = true;
                  }
                });
              }
            }
          })
        } else {
          this.alertService.Toast({ message: `${data.Message}`, type: CLToastType.ERROR, darkMode:true,horizontalPosition:"right", verticalPosition:"top" })
          this.MenuNodes.forEach(x=>{
              if (x.Key != "home" && x.Key != "logout")
                x.Visible = false;
            }
          )
          this.router.navigate(['/home']);
        }
        this.linkerService.Publish(
          {
            CallBack: CL_CHANNEL.INFLATE,
            Data: JSON.stringify(this.MenuNodes),
            Target: this.MenuId
          }
        );
      }
    })
  }

  ClickMenuOption = (_event: ICLEvent): void => {
    const menuNode: IMenuNode = JSON.parse(_event.Data) as IMenuNode;
    switch (menuNode.Key) {
      case "logout":
      //  this.aut.logout();
    }
  }


  ToggleMenu(): void {
    this.Toggle = !this.Toggle;
    this.linkerService.Publish({
      CallBack: CL_CHANNEL.DATA_LINE_1,
      Target: this.MenuId,
      Data: JSON.stringify(this.Toggle)
    } as ICLEvent);
  }
  //Funcion para abrir la modal de cambio de compania

  OpenSelectCompanyDialog():void{
    const data ={
    }

    const matDialogRef = this.dialog.open(SelectCompanyDialogComponent,{width:'50%',data:data});
    try{

      matDialogRef.afterClosed().pipe(
        finalize(()=> window.location.reload())
      ).subscribe({
        next:(result:boolean)=>{
          this.SelectedCompany= JSON.parse(this.storage.GetSelectedCompany());
          this.txtcompany= this.SelectedCompany?.companyName;
          return result;
        },
        error: (err):void=>{
          this.alertService.ShowAlert({HttpErrorResponse: err});
        }
      });


    }catch (e) {
      this.alertService.Toast({ message: `Falló al abrir la modal`, type: CLToastType.SUCCESS , horizontalPosition: "right",verticalPosition:"top",darkMode:true});

    }
  }
  GetRequiredPerm(route: string):string {
    let requiredPerm: string = '';
    switch (route) {
      case 'mailParser': {
        requiredPerm = 'S_MailParserLogs';
        break;
      }
      case 'updateUserInfo': {
        requiredPerm = 'S_UpdateUserInfo';
        break;
      }
      case 'company': {
        requiredPerm = 'S_Company';
        break;
      }
      case 'completeUsersRegistration': {
        requiredPerm = 'S_CompUser';
        break;
      }
      case 'createFE': {
        requiredPerm = 'S_CreateDocsFE';
        break;
      }
      case 'createND': {
        requiredPerm = 'S_CreateDocsND';
        break;
      }
      case 'createNC': {
        requiredPerm = 'S_CreateDocsNC';
        break;
      }
      case 'createTE': {
        requiredPerm = 'S_CreateDocsTE';
        break;
      }
      case 'createFEC': {
        requiredPerm = 'S_CreateDocsFEC';
        break;
      }
      case 'createFEE': {
        requiredPerm = 'S_CreateDocsFEE';
        break;
      }
      case 'reports': {
        requiredPerm = 'M_Reports';
        break;
      }
      case 'issued_documents': {
        requiredPerm = 'S_RevDocs';
        break;
      }
      case 'accept_documents': {
        requiredPerm = 'S_AcceptDocs';
        break;
      }
      case 'accept_documents_gt': {
        requiredPerm = 'S_AcceptDocsGT';
        break;
      }
      case 'reception_documents': {
        requiredPerm = 'S_ReceptDocs';
        break;
      }
      case 'generalConfigs': {
        requiredPerm = 'sys@clavisco.com';
        break;
      }
      case 'groups': {
        requiredPerm = 'S_Groups';
        break;
      }
      case 'userAssigment': {
        requiredPerm = 'S_AsigUser';
        break;
      }
      case 'numbering': {
        requiredPerm = 'S_Numbering';
        break;
      }
      case 'permsByRol': {
        requiredPerm = 'S_PermsByRol';
        break;
      }
      // case 'docReport': {
      //   requiredPerm = 'S_DocumentReceptionReport';
      //   break;
      // }
      case 'registerUsers': {
        requiredPerm = 'S_RegUser';
        break;
      }
      case 'Rol': {
        requiredPerm = 'S_Rols';
        break;
      }
      case 'rolUserCompany': {
        requiredPerm = 'S_RolByUser';
        break;
      }
      case 'sucursal': {
        requiredPerm = 'S_Sucursal';
        break;
      }
      // PRUEBA
      case 'mailParserConfig': {
        requiredPerm = 'S_MailParserConfig';
        break;
      }
      case 'emailInbox': {
        requiredPerm = 'sys@clavisco.com';
        break;
      }
      case 'udfs': {
        requiredPerm = 'S_Udfs';
        break;
      }

      case 'documents':{
        requiredPerm = 'M_Documents';
        break;
      }
      case 'settings':{
        requiredPerm = 'M_Config';
        break;
      }
    }
    return requiredPerm;
  }
}
