import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Dialog} from "@angular/cdk/dialog";
import {MailParserConfigComponent} from "../mail-parser-config.component";
import {MailParserService} from "../../../../core/services/mail-parser.service";
import {MailParserConfigModel} from "../../../../core/interfaces/i-mailParserConfig";
import {finalize, forkJoin, map, Observable, of, startWith, tap} from "rxjs";
import {AllCompanyInfo, CompanyModel} from "../../../../models/Company";
import {AllCompaniesResponse, BaseResponse} from "../../../../models/responses";
import {CompanyModule} from "../../company/company.module";
import {AlertsService, CLModalType, CLToastType, ModalService} from "@clavisco/alerts";
import {OverlayService} from "@clavisco/overlay";
import {GetError, Structures} from "@clavisco/core";
import ICLResponse = Structures.Interfaces.ICLResponse;

@Component({
  selector: 'app-mail-parser-modal',
  templateUrl: './mail-parser-modal.component.html',
  styleUrls: ['./mail-parser-modal.component.scss']
})
export class MailParserModalComponent implements OnInit {

  mailParserForm!: FormGroup;

  isChecked: boolean = false;

  mailServer: string | undefined; // variable para buscar por nombre de server
  email: string | undefined; // variable para buscar por correo
  server: string | undefined; // variable para puerto
  companyName: string | undefined; // variable para buscar nombre de la compania

  status: number | undefined;
  useToken: number | undefined;

  statusList: any[] = [{ Name: 'Ambos', Value: 2 },{ Name: 'Activo', Value: 1 }, { Name: 'Inactivo', Value: 0 }]; // lista de estados
  tokenList: any[] = [{ Name: 'Ambos', Value: 2 },{ Name: 'Si', Value: 1 }, { Name: 'No', Value: 0 }]; // lista de estados

  companyList: AllCompanyInfo[] = []; // lista de companias

  //#Valor prueba


  filteredOptions: Observable<CompanyModel[]>  = of([]);
  constructor(
    public dialogRef: MatDialogRef<MailParserModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private mailParserService: MailParserService,
    private alertService: AlertsService,
    private modalService: ModalService,
    @Inject('OverlayService') private overlayService : OverlayService

  ) { }

  ngOnInit(): void {


    this.LoadInitData();
  }

  //#VAlor prueba
  private _Filter(value: any): any[] {

    if( typeof value.CompanyId === 'string'){
      console.log(value.CompanyId);
      const filterValue = value.CompanyId?.toLowerCase();
      return this.companyList.filter((option:AllCompanyInfo) => option.ComercialName.toLowerCase().includes(filterValue));
    }else return []

  }

  DisplayCompanyFn(option: CompanyModel): string {
    return option ? option.ComercialName : '';
  }

  LoadInitData():void{
    this.overlayService.OnGet()
    this.status = 2;
    this.useToken = 2;
    this.email = "";
    this.mailServer = "";
    this.companyName = "";
   // this.GetMailParser();
    let companies$ = this.mailParserService.GetAllCompanies();
  //  this.mailParserList.length = 0;
    this.mailParserForm = this.CreateMPForm();


    forkJoin(
      [companies$]
    ).pipe(
      finalize(()=>this.overlayService.Drop())
    ).subscribe({
      next:(data):void=>{
        if(data){
          let sms: string = '';

          let mParserResp: ICLResponse<AllCompanyInfo[]> = data[0];
          if (mParserResp) {
            if (mParserResp.Data.length) {
              this.companyList = mParserResp.Data;

              console.log('Bandejas: ', mParserResp.Data);
            } else {
              sms = (`${mParserResp.Message}`);
            }
          }
          //  this.blockUI.stop(); // Stop blocking

          if (sms.length > 0) {
            this.alertService.Toast({ message: `${sms}`, type: CLToastType.WARNING , darkMode:true,horizontalPosition:"right", verticalPosition:"top"})

          }
          console.log(this.data)
          if(this.data){
            this.mailParserForm.get('Password')?.clearValidators();
            this.mailParserForm.get('Password')?.updateValueAndValidity();
            this.PatchMPForm();
          }
        }
      },
      error: (err):void => {
        this.modalService.Continue({
          title: 'Se produjo un error al obtener lsa compañías',
          subtitle: GetError(err),
          type: CLModalType.ERROR
        });
      }
    })
    this.filteredOptions = this.mailParserForm.valueChanges?.pipe(
      startWith(''),
      map(value => this._Filter(value || '')),
    );


  }

  CloseModal(status: boolean): void {
    this.dialogRef.close(status);
  }


  CreateMPForm():FormGroup {

    return new FormGroup({
      Id: new FormControl(0),
      ServerDirection: new FormControl('', Validators.required),
      Email: new FormControl('', Validators.compose([Validators.required, Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$')])),
      Password: new FormControl('', Validators.required),
      Port: new FormControl('', Validators.required),
      IsAutomatic: new FormControl(false),
      Status: new FormControl(true),
      CompanyId: new FormControl('', Validators.required),
      TenantId: new FormControl('',Validators.required),
      URL: new FormControl('', Validators.required),
      GrantType: new FormControl('', Validators.required),
      Scope: new FormControl('', Validators.required),
      ClientSecret: new FormControl('',Validators.required),
      ClientId: new FormControl('', Validators.required),
      UseToken: new FormControl(false),


    });
  }

  PatchMPForm():void{

    this.companyList;

    const company = this.companyList.find(x => x.Id === this.data.CompanyId);
    // if (company) {
    //
    //   this.mailParserForm.patchValue({ CompanyId: company })
    //
    // }

    this.mailParserForm.setValue({
      Id: this.data.Id,
      ServerDirection: this.data.MailServer,
      Email: this.data.Email,
      Password: '',
      Port: this.data.Port,
      IsAutomatic: this.data.IsAutomatic === "Si" ? true : false,
      Status: this.data.Status === "Activo"? true: false,
      CompanyId: company? company:'',
      TenantId: this.data.TenantId,
      URL: this.data.Url,
      GrantType: this.data.GrantType,
      Scope: this.data.Scope,
      ClientSecret: this.data.ClientSecret,
      ClientId: this.data.ClientId,
      UseToken: this.data.UseToken === "Si" ? true : false,

    })
  }
  CreateOrUpdateMP( isCreation: boolean): void{

    //Si no sua token se eliminan los espacios requeridos que no son necesarios para esta configuracion
    if(this.mailParserForm.controls['UseToken'].value !== true){

      this.mailParserForm.get('TenantId')?.clearValidators();
      this.mailParserForm.get('TenantId')?.updateValueAndValidity();
      this.mailParserForm.get('URL')?.clearValidators();
      this.mailParserForm.get('URL')?.updateValueAndValidity();
      this.mailParserForm.get('GrantType')?.clearValidators();
      this.mailParserForm.get('GrantType')?.updateValueAndValidity();
      this.mailParserForm.get('Scope')?.clearValidators();
      this.mailParserForm.get('Scope')?.updateValueAndValidity();
      this.mailParserForm.get('ClientId')?.clearValidators();
      this.mailParserForm.get('ClientId')?.updateValueAndValidity();
      this.mailParserForm.get('ClientSecret')?.clearValidators();
      this.mailParserForm.get('ClientSecret')?.updateValueAndValidity();
    }else{

      this.mailParserForm.get('TenantId')?.setValidators(Validators.required);
      this.mailParserForm.get('TenantId')?.updateValueAndValidity();
      this.mailParserForm.get('URL')?.setValidators(Validators.required);
      this.mailParserForm.get('URL')?.updateValueAndValidity();
      this.mailParserForm.get('GrantType')?.setValidators(Validators.required);
      this.mailParserForm.get('GrantType')?.updateValueAndValidity();
      this.mailParserForm.get('Scope')?.setValidators(Validators.required);
      this.mailParserForm.get('Scope')?.updateValueAndValidity();
      this.mailParserForm.get('ClientId')?.setValidators(Validators.required);
      this.mailParserForm.get('ClientId')?.updateValueAndValidity();
      this.mailParserForm.get('ClientSecret')?.setValidators(Validators.required);
      this.mailParserForm.get('ClientSecret')?.updateValueAndValidity();
      this.mailParserForm.get('Password')?.clearValidators();
      this.mailParserForm.get('Password')?.updateValueAndValidity();
    }

    if(this.mailParserForm.valid){
      const parser : MailParserConfigModel={
        Id:this.mailParserForm.getRawValue().Id,
        MailServer: this.mailParserForm.getRawValue().ServerDirection,
        Email: this.mailParserForm.getRawValue().Email,
        Password:this.mailParserForm.getRawValue().Password,
        Port:String(this.mailParserForm.getRawValue().Port),
        IsAutomatic:this.mailParserForm.getRawValue().IsAutomatic,
        Status: this.mailParserForm.getRawValue().Status==true?1:0,
        CompanyId:  this.mailParserForm.getRawValue().CompanyId.Id,
        TenantId:this.mailParserForm.getRawValue().TenantId,
        Url:this.mailParserForm.getRawValue().URL,
        GrantType:this.mailParserForm.getRawValue().GrantType,
        Scope:this.mailParserForm.getRawValue().Scope,
        ClientSecret:this.mailParserForm.getRawValue().ClientSecret,
        ClientId:this.mailParserForm.getRawValue().ClientId,
        UseToken:this.mailParserForm.getRawValue().UseToken
      };
      this.overlayService.OnGet();
      if(this.mailParserForm.controls['Id'].value != undefined && this.mailParserForm.controls['Id'].value > 0){

        this.mailParserService.EditMailParser(parser).pipe(
          finalize(()=>this.overlayService.Drop())
        ).subscribe({
          next:(data:ICLResponse<BaseResponse>):void=>{
           // if(data.result){
              this.alertService.Toast({ message: `Bandeja actualizada correctamente!`, type: CLToastType.SUCCESS , darkMode:true,horizontalPosition:"right", verticalPosition:"top"})
              this.dialogRef.close(true);
            // }else{
            //   this.alertService.Toast({ message: `Error al actualizar la bandeja`, type: CLToastType.WARNING , darkMode:true,horizontalPosition:"right", verticalPosition:"top"})
            // }
          }
        })
      }else{
        this.mailParserService.CreateMailParser(parser).pipe(
          finalize(()=>this.overlayService.Drop())
        ).subscribe({
          next:(data:ICLResponse<BaseResponse>):void=>{
           // if(data.result){
              this.alertService.Toast({ message: `Bandeja creada correctamente!`, type: CLToastType.SUCCESS , darkMode:true,horizontalPosition:"right", verticalPosition:"top"})
              this.dialogRef.close(true);
            // }else{
            //   this.alertService.Toast({ message: `Error al crear la bandeja`, type: CLToastType.WARNING , darkMode:true,horizontalPosition:"right", verticalPosition:"top"})
            // }
          }

        })
      }

    }else {

      //Marca los imputs como tocados
      Object.values(this.mailParserForm.controls).forEach(control => {
        control.markAsTouched();
      });
      this.alertService.Toast({ message: `Favor completar los espacios requeridos`, type: CLToastType.WARNING , darkMode:true,horizontalPosition:"right", verticalPosition:"top"})
    }
  }

}
