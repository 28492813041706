


import {Menu} from "@angular/cdk/menu";
import {OCType} from "../core/interfaces/i-user";


export class AppConstants {

  // public static get GetMenu(): Menu[] { return this.MENU; }
  public static get GetMenu(): Menu[] {
    return JSON.parse(JSON.stringify(this.MENU));
  }

  private static MENU = [ // Menu principal de la aplicacion
    {
      Name: 'Inicio',
      Title: 'Estadística',
      Route: 'home',
      Img: '',
      Icon: 'fa fa-home fa-lg title',
      Children: [],
      Selected: true,
      Type: 1,
      RequiredPerm: '',
      Visible: true,
      Expanded: false
    },
    {
      Name: 'Documentos',
      Title: 'Documentos',
      Route: '',
      Img: '',
      Icon: 'fa fa-book fa-lg title parent-icon',
      Children: [
        {
          Name: 'Documentos Emitidos',
          Title: 'Búsqueda de Documentos',
          Route: '/documents',
          Img: '',
          Icon: 'fa fa-file-o subtitle',
          Children: [],
          Selected: false,
          Type: 2,
          RequiredPerm: 'S_RevDocs',
          Visible: false,
          Expanded: false
        },
        {
          Name: 'Aceptación Documentos',
          Title: 'Búsqueda de Documentos Aceptados',
          Route: '/documentsAccepted',
          Img: '',
          Icon: 'fa fa-check-square-o',
          Children: [],
          Selected: false,
          Type: 2,
          RequiredPerm: 'S_AcceptDocs',
          Visible: false,
          Expanded: false
        },
        {
          Name: 'Aceptación Documentos GT',
          Title: 'Búsqueda de Documentos Aceptados GT',
          Route: '/documentsAcceptedGT',
          Img: '',
          Icon: 'fa fa-check-square-o',
          Children: [],
          Selected: false,
          Type: 2,
          RequiredPerm: 'S_AcceptDocsGT',
          Visible: false,
          Expanded: false
        },
        {
          Name: 'Recepción Documentos',
          Title: 'Recepción de Documentos',
          Route: '/documentsReception',
          Img: '',
          Icon: 'fa fa-upload',
          Children: [],
          Selected: false,
          Type: 2,
          RequiredPerm: 'S_ReceptDocs',
          Visible: false,
          Expanded: false
        },
        {
          Name: 'Logs de Correo de Recepción',
          Title: 'Búsqueda de Logs del Correo de Recepción',
          Route: '/mailParserLogs',
          Img: '',
          Icon: 'fa fa-file-text-o',
          Children: [],
          Selected: false,
          Type: 2,
          RequiredPerm: 'S_MailParserLogs',
          Visible: false,
          Expanded: false
        },
        {
          Name: 'Creación FE',
          Title: 'Facturación - Factura Electrónica',
          Route: '/createDocument/01',
          Img: './../../../../assets/img/docsIcons/FEBlanco.png',
          Icon: '',
          Children: [],
          Selected: false,
          Type: 2,
          RequiredPerm: 'S_CreateDocsFE',
          Visible: false,
          Expanded: false
        },
        {
          Name: 'Creación ND',
          Title: 'Facturación - Nota de Débito Electrónica',
          Route: '/createDocument/02',
          Img: './../../../../assets/img/docsIcons/NDBlanco.png',
          Icon: '',
          Children: [],
          Selected: false,
          Type: 2,
          RequiredPerm: 'S_CreateDocsND',
          Visible: false,
          Expanded: false
        },
        {
          Name: 'Creación NC',
          Title: 'Facturación - Nota de Crédito Electrónica',
          Route: '/createDocument/03',
          Img: './../../../../assets/img/docsIcons/NCBlanco.png',
          Icon: '',
          Children: [],
          Selected: false,
          Type: 2,
          RequiredPerm: 'S_CreateDocsNC',
          Visible: false,
          Expanded: false
        },
        {
          Name: 'Creación FEC',
          Title: 'Facturación - Factura Electrónica de Compra',
          Route: '/createDocument/08',
          Img: './../../../../assets/img/docsIcons/FECBlanco.png',
          Icon: '',
          Children: [],
          Selected: false,
          Type: 2,
          RequiredPerm: 'S_CreateDocsFEC',
          Visible: false,
          Expanded: false
        }// ,
        // {
        //     Name: 'Creación TE',
        //     Title: 'Facturación - Tiquete Electrónico',
        //     Route: '/createDocument/04',
        //     Img: './../../../../assets/img/docsIcons/TEBlanco.png',
        //     Icon: '',
        //     Children: [],
        //     Selected: false,
        //     Type: 2,
        //     RequiredPerm: 'S_CreateDocsTE',
        //     Visible: false,
        //     Expanded: false
        // },
        // {
        //     Name: 'Creación FEE',
        //     Title: 'Facturación - Factura Electrónica de Exportación',
        //     Route: '/createDocument/09',
        //     Img: './../../../../assets/img/docsIcons/FEEBlanco.png',
        //     Icon: '',
        //     Children: [],
        //     Selected: false,
        //     Type: 2,
        //     RequiredPerm: 'S_CreateDocsFEE',
        //     Visible: false,
        //     Expanded: false
        // }
      ],
      Selected: false,
      Type: 1,
      RequiredPerm: 'M_Documents',
      Visible: false,
      Expanded: false
    },
    {
      Name: 'Reportes',
      Title: 'Reportes',
      Route: '',
      Img: '',
      Icon: 'fa fa-print fa-lg title parent-icon',
      Children: [
        {
          Name: 'Reporte de Documentos Facturados',
          Title: 'Reporte de Documentos',
          Route: '/docReport',
          Img: './../../../../assets/img/docsIcons/RDBlanco.png',
          Icon: '',
          Children: [],
          Selected: false,
          Type: 2,
          RequiredPerm: 'S_DocumentReport',
          Visible: false,
          Expanded: false
        },
        {
          Name: 'Reporte de Documentos Recepcionados',
          Title: 'Reporte de Documentos Recepcionados',
          Route: '/receptionReport',
          Img: './../../../../assets/img/docsIcons/RRBlanco.png',
          Icon: '',
          Children: [],
          Selected: false,
          Type: 2,
          RequiredPerm: 'S_DocumentReceptionReport',
          Visible: false,
          Expanded: false
        }
      ],
      Selected: false,
      Type: 1,
      RequiredPerm: 'M_Reports',
      Visible: false,
      Expanded: false
    },
    {
      Name: 'Configuración',
      Title: 'Configuración',
      Route: '',
      Img: '',
      Icon: 'fa fa-cogs fa-lg title parent-icon',
      Children: [
        {
          Name: 'Compañía',
          Title: 'Búsqueda de Compañías',
          Route: '/companies',
          Img: '',
          Icon: 'fa fa-building subtitle',
          Children: [],
          Selected: false,
          Type: 2,
          RequiredPerm: 'S_Company',
          Visible: false,
          Expanded: false
        },
        {
          Name: 'Campos definidos por usuario (UDFs)',
          Title: 'UDFs Dinamicos',
          Route: '/udfs',
          Img: '',
          Icon: 'fa fa-building subtitle',
          Children: [],
          Selected: false,
          Type: 2,
          RequiredPerm: 'S_Udfs',
          Visible: false,
          Expanded: false
        },
        {
          Name: 'Registro Usuarios',
          Title: 'Registrar Nuevo Usuario',
          Route: '/registerUsers',
          Img: '',
          Icon: 'fa fa-user-plus subtitle',
          Children: [],
          Selected: false,
          Type: 2,
          RequiredPerm: 'S_RegUser',
          Visible: false,
          Expanded: false
        },
        {
          Name: 'Completar Registro de Usuarios',
          Title: 'Completar Registro de Usuarios',
          Route: '/completeRegisterUsers',
          Img: '',
          Icon: 'fa fa-user-circle subtitle',
          Children: [],
          Selected: false,
          Type: 2,
          RequiredPerm: 'S_CompUser',
          Visible: false,
          Expanded: false
        },
        {
          Name: 'Asignación de Usuarios',
          Title: 'Asignación de Usuarios',
          Route: '/listUsers',
          Img: '',
          Icon: 'fa fa-users subtitle',
          Children: [],
          Selected: false,
          Type: 2,
          RequiredPerm: 'S_AsigUser',
          Visible: false,
          Expanded: false
        },
        {
          Name: 'Grupos',
          Title: 'Cuenta',
          Route: '/groups',
          Img: '',
          Icon: 'fa fa-sitemap subtitle',
          Children: [],
          Selected: false,
          Type: 2,
          RequiredPerm: 'S_Groups',
          Visible: false,
          Expanded: false
        },
        {
          Name: 'Numeración',
          Title: 'Numeración',
          Route: '/numbering',
          Img: '',
          Icon: 'fa fa-list-ol subtitle',
          Children: [],
          Selected: false,
          Type: 2,
          RequiredPerm: 'S_Numbering',
          Visible: false,
          Expanded: false
        },
        {
          Name: 'Permisos por Rol',
          Title: 'Asignación de Permisos por Rol',
          Route: '/permsByRol',
          Img: '',
          Icon: 'fa fa-list subtitle',
          Children: [],
          Selected: false,
          Type: 2,
          RequiredPerm: 'S_PermsByRol',
          Visible: false,
          Expanded: false
        },
        {
          Name: 'Roles',
          Title: 'Roles Existentes',
          Route: '/rol',
          Img: '',
          Icon: 'fa fa-cog subtitle',
          Children: [],
          Selected: false,
          Type: 2,
          RequiredPerm: 'S_Rols',
          Visible: false,
          Expanded: false
        },
        {
          Name: 'Roles por Usuario',
          Title: 'Asignación Rol por Usuario y Compañía',
          Route: '/rolUserCompany',
          Img: '',
          Icon: 'fa fa-cog subtitle',
          Children: [],
          Selected: false,
          Type: 2,
          RequiredPerm: 'S_RolByUser',
          Visible: false,
          Expanded: false
        },
        {
          Name: 'Actualizar Información del Usuario',
          Title: 'Actualización de la Información del Usuario',
          Route: '/updateUserInfo',
          Img: '',
          Icon: 'fa fa-user subtitle',
          Children: [],
          Selected: false,
          Type: 2,
          RequiredPerm: 'S_UpdateUserInfo',
          Visible: false,
          Expanded: false
        },
        {
          Name: 'Sucursal',
          Title: 'Sucursal',
          Route: '/sucursal',
          Img: '',
          Icon: 'fa fa-building ',
          Children: [],
          Selected: false,
          Type: 2,
          RequiredPerm: 'S_Sucursal',
          Visible: false,
          Expanded: false
        },
        {
          Name: 'Procesador de Correos',
          Title: 'Procesador de Correos',
          Route: '/mailParser',
          Img: '',
          Icon: 'fa fa-building subtitle',
          Children: [],
          Selected: false,
          Type: 2,
          RequiredPerm: 'S_MailParserConfig',
          Visible: false,
          Expanded: false
        }
      ],
      Selected: false,
      Type: 1,
      RequiredPerm: 'M_Config',
      Visible: false,
      Expanded: false
    },
    {
      Name: 'General Config.',
      Title: 'Configuraciones Generales',
      Route: '/generalConfigs',
      Img: '',
      Icon: 'fa fa-cog fa-lg title parent-icon',
      Children: [],
      Selected: false,
      Type: 1,
      RequiredPerm: 'sys@clavisco.com',
      Visible: false,
      Expanded: false
    },
    {
      Name: 'Salir',
      Title: 'Salir',
      Route: '',
      Img: '',
      Icon: 'fa fa-sign-out fa-lg title parent-icon',
      Children: [],
      Selected: false,
      Type: 1,
      RequiredPerm: '',
      Visible: true,
      Expanded: false
    }
  ];
}

export const IdentificationType = [
  {
    'Id': '01',
    'Name': 'Cédula Fisica',
  },
  {
    'Id': '02',
    'Name': 'Cedula Juridica',
  },
  {
    'Id': '03',
    'Name': 'DIMEX',
  },
  {
    'Id': '04',
    'Name': 'NITE',
  }
];

export const DocStatus = [
  {
    'Id': '1',
    'Name': 'Aceptado',
  },
  {
    'Id': '2',
    'Name': 'Procesando',
  },
  {
    'Id': '3',
    'Name': 'En Hacienda',
  },
  {
    'Id': '4',
    'Name': 'Rechazado',
  },
  {
    'Id': '5',
    'Name': 'Error',
  },
  {
    'Id': '99',
    'Name': 'Todos',
  }
];

export const DocStatusEmailModal = [
  {
    'Id': '1',
    'Name': 'Aceptado',
  },
  {
    'Id': '2',
    'Name': 'Procesando',
  },
  {
    'Id': '3',
    'Name': 'En Hacienda',
  },
  {
    'Id': '4',
    'Name': 'Rechazado',
  },
  {
    'Id': '5',
    'Name': 'Error',
  },
  {
    'Id': '6',
    'Name': 'Reprocesar',
  },
  {
    'Id': '99',
    'Name': 'Todos',
  }
];

export const DocStatusRecept = [
  {
    'Id': '0',
    'Name': 'Todos',
  },
  {
    'Id': '1',
    'Name': 'Aceptado',
  },
  {
    'Id': '2',
    'Name': 'Procesando',
  },
  {
    'Id': '3',
    'Name': 'En Hacienda',
  },
  {
    'Id': '4',
    'Name': 'Rechazado',
  },
  {
    'Id': '5',
    'Name': 'Error',
  },
  {
    'Id': '7',
    'Name': 'Obtenido Correo',
  },
  {
    'Id': '8',
    'Name': 'Obtenido Correo Automático',
  },

];

export const DocTypes = [
  {
    'Id': '01',
    'Name': 'FE',
  },
  {
    'Id': '02',
    'Name': 'ND',
  },
  {
    'Id': '03',
    'Name': 'NC',
  },
  {
    'Id': '04',
    'Name': 'TE',
  },
  {
    'Id': '08',
    'Name': 'FEC',
  },
  {
    'Id': '09',
    'Name': 'FEE',
  }
];
export const CondicionVenta = [
  {
    'Id': '01',
    'Name': 'Contado',
  },
  {
    'Id': '02',
    'Name': 'Crédito',
  },
  {
    'Id': '03',
    'Name': 'Consignación',
  },
  {
    'Id': '04',
    'Name': 'Apartado',
  },
  {
    'Id': '05',
    'Name': 'Arrendamiento con opción de compra',
  },
  {
    'Id': '06',
    'Name': 'Arrendamiento en función financiera',
  },
  {
    'Id': '07',
    'Name': 'Cobro a favor de un tercero',
  },
  {
    'Id': '08',
    'Name': 'Servicios prestados al Estado a crédito',
  },
  {
    'Id': '09',
    'Name': 'Pago del servicios prestado al Estado ',
  },
  {
    'Id': '99',
    'Name': 'Otros',
  }
];

export const MessageType = [
  {
    'Id': 1,
    'Name': 'Aceptado',
  },
  {
    'Id': 2,
    'Name': 'Aceptar Parcialmente',
  },
  {
    'Id': 3,
    'Name': 'Rechazado',
  }
];

export const TaxCondition = [
  {
    'Value': '01',
    'Description': 'Genera Credito IVA'
  },
  {
    'Value': '02',
    'Description': 'Genera Crédito parcial del IVA'
  },

  {
    'Value': '03',
    'Description': 'Bienes de Capital'
  },

  {
    'Value': '04',
    'Description': 'Gasto corriente no genera crédito'
  },

  {
    'Value': '05',
    'Description': 'Proporcionalidad'
  }
];

export const MessageTypeAcceptDocs = [
  {
    'Id': 1,
    'Name': 'Aceptado',
  },
  {
    'Id': 2,
    'Name': 'Aceptado Parcialmente',
  },
  {
    'Id': 3,
    'Name': 'Rechazado',
  }
];

export const AcceptStatus = [
  {
    'Id': '1',
    'Name': 'Aceptado',
  },
  {
    'Id': '2',
    'Name': 'Rechazado',
  },
  {
    'Id': '3',
    'Name': 'Procesando',
  },
  {
    'Id': '4',
    'Name': 'Error',
  }
];

export const MessageStatus = [
  {
    'Id': '1',
    'Name': 'Pendiente',
  },
  {
    'Id': '2',
    'Name': 'Enviando',
  },
  {
    'Id': '3',
    'Name': 'Error',
  },
  {
    'Id': '4',
    'Name': 'Enviado',
  }
];

export const BandejaCorreoStatus = [
  {
    'Id': '1',
    'Name': 'Listo'
  },
  {
    'Id': '2',
    'Name': 'En Proceso'
  },
  {
    'Id': '3',
    'Name': 'Error'
  },
  {
    'Id': '4',
    'Name': 'Enviado'
  }
];

export const EmailSendType = [
  {
    'Id': '1',
    'Name': 'Envio'
  },
  {
    'Id': '2',
    'Name': 'Reenvio'
  },
  {
    'Id': '3',
    'Name': 'Receptor'
  }
];

export const PaymentMethod = [
  {
    'Id': '01',
    'Value': 'Efectivo'
  },
  {
    'Id': '02',
    'Value': 'Tarjeta'
  },
  {
    'Id': '03',
    'Value': 'Cheque'
  },
  {
    'Id': '04',
    'Value': 'Transferencia - depósito bancario'
  },
  {
    'Id': '05',
    'Value': 'Recaudado por terceros'
  },
  {
    'Id': '99',
    'Value': 'Otros'
  }
];

export const Currency = [
  {
    'Id': 'COL',
    'Value': 'Colones'
  },
  {
    'Id': 'USD',
    'Value': 'Dólares'
  }
];

export const ProductType = [
  {
    'Id': '01',
    'Value': 'Producto'
  },
  {
    'Id': '02',
    'Value': 'Servicio'
  }
];

export const CurrencyATV = [
  {
    'Id': 'CRC',
    'Value': 'Colones'
  },
  {
    'Id': 'USD',
    'Value': 'Dólares'
  },
  {
    'Id': 'EUR',
    'Value': 'Euros'
  }
];

export const CodigoTarifaList = [
  {
    'Id': '01',
    'Value': 'Tarifa 0% (Exento)'
  },
  {
    'Id': '02',
    'Value': 'Tarifa reducida 1%'
  },
  {
    'Id': '03',
    'Value': 'Tarifa reducida 2%'
  },
  {
    'Id': '04',
    'Value': 'Tarifa reducida 4%'
  },
  {
    'Id': '05',
    'Value': 'Transitorio 0%'
  },
  {
    'Id': '06',
    'Value': 'Transitorio 4%'
  },
  {
    'Id': '07',
    'Value': 'Transitorio 8%'
  },
  {
    'Id': '08',
    'Value': 'Tarifa general 13%'
  }
];

export const CodigoTarifaListValues = [
  {
    'Id': '01',
    'Value': '0'
  },
  {
    'Id': '02',
    'Value': '1'
  },
  {
    'Id': '03',
    'Value': '2'
  },
  {
    'Id': '04',
    'Value': '4'
  },
  {
    'Id': '05',
    'Value': '0'
  },
  {
    'Id': '06',
    'Value': '4'
  },
  {
    'Id': '07',
    'Value': '8'
  },
  {
    'Id': '08',
    'Value': '13'
  }
];


export const TipoDocRefList = [
  {
    'Id': '01',
    'Value': 'Factura electrónica'
  },
  {
    'Id': '02',
    'Value': 'Nota de débito electrónica'
  },
  {
    'Id': '03',
    'Value': 'Nota de crédito electrónica'
  },
  {
    'Id': '04',
    'Value': 'Tiquete electrónico'
  },
  {
    'Id': '05',
    'Value': 'Nota de despacho'
  },
  {
    'Id': '06',
    'Value': 'Contrato'
  },
  {
    'Id': '07',
    'Value': 'Procedimiento'
  },
  {
    'Id': '08',
    'Value': 'Comprobante emitido en contingencia'
  },
  {
    'Id': '09',
    'Value': 'Devolución mercadería'
  },
  {
    'Id': '10',
    'Value': 'Sustituye factura rechazada por el Ministerio de Hacienda'
  },
  {
    'Id': '11',
    'Value': 'Sustituye factura rechazada por el Receptor del comprobante'
  },
  {
    'Id': '12',
    'Value': 'Sustituye Factura de exportación'
  },
  {
    'Id': '13',
    'Value': 'Facturación mes vencido'
  },
  {
    'Id': '14',
    'Value': 'Comprobante aportado por contribuyente del Régimen de Tributación Simplificado'
  },
  {
    'Id': '15',
    'Value': 'Sustituye una Factura electrónica de Compra '
  },
  {
    'Id': '99',
    'Value': 'Otros'
  }
];

export const CodigoRefList = [
  {
    'Id': '01',
    'Value': 'Anula Documento de Referencia'
  },
  {
    'Id': '02',
    'Value': 'Corrige monto'
  },
  {
    'Id': '04',
    'Value': 'Referencia a otro documento'
  },
  {
    'Id': '05',
    'Value': 'Sustituye comprobante provisional por contingencia'
  },
  {
    'Id': '99',
    'Value': 'Otros'
  }
];

// EXONERACION LINEAS DE ITEMS EN CREACION DE DOCS
export const TipoDocExoneracion = [
  {
    'Id': '01',
    'Value': 'Compras autorizadas'
  },
  {
    'Id': '02',
    'Value': 'Ventas exentas a diplomáticos'
  },
  {
    'Id': '03',
    'Value': 'Autorizado por Ley especial'
  },
  {
    'Id': '04',
    'Value': 'Exenciones Dirección General de Hacienda'
  },
  {
    'Id': '05',
    'Value': 'Transitorio V'
  },
  {
    'Id': '06',
    'Value': 'Transitorio IX'
  },
  {
    'Id': '07',
    'Value': 'Transitorio XVII'
  },
  {
    'Id': '99',
    'Value': 'Otros'
  }
];

export const TipoDocBase = [
  {
    'Id': '01',
    'Value': 'Oferta'
  },
  {
    'Id': '02',
    'Value': 'Orden'
  },
  {
    'Id': '03',
    'Value': 'Entrada'
  },
  {
    'Id': '04',
    'Value': 'Ninguno'
  }
];


// Enum para reconocer que nombre de la company se utilizara para el envio de correos
export const NameToEmailType = [
  {
    'Id': 1,
    'Value': 'Nombre Legal'
  },
  {
    'Id': 2,
    'Value': 'Nombre Comercial'
  }
];
export const FreightChargesType = [
  {
    'Id': 1,
    'Value': 'Articulos'
  },
  {
    'Id': 2,
    'Value': 'Otros Cargos'
  }
];

export  const OCTypes : OCType[] = [
  {
    'Id': 1,
    'Value': 'Con numero de OC'
  },
  {
    'Id': 2,
    'Value': 'Sin numero de OC'
  }
]

/**
 * Constante `OCTypesFilter`
 *
 * Esta constante define un array de objetos de tipo `OCType`, utilizados para
 * filtrar tipos de OC (Orden de Compra) en una aplicación. Cada objeto contiene
 * un identificador (`Id`) y un valor descriptivo (`Value`).
 *
 * Tipos de OC disponibles:
 * - `Id: 0, Value: 'Todos'` - Incluye todos los tipos de OC.
 * - `Id: 1, Value: 'Con numero de OC'` - Filtra OCs que tienen un número de OC asignado.
 * - `Id: 2, Value: 'Sin numero de OC'` - Filtra OCs que no tienen un número de OC asignado.
 */
export  const OCTypesFilter : OCType[] = [
  {
    'Id': 0,
    'Value': 'Todos'
  },
  {
    'Id': 1,
    'Value': 'Con numero de OC'
  },
  {
    'Id': 2,
    'Value': 'Sin numero de OC'
  }
]
